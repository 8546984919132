import {Injectable} from '@angular/core';
import {NisApp} from '../../types/nis/nis.types';
import {AppConfigTypes} from '../../types/config/app-config.types';
import {Subject} from 'rxjs';
import {NisHttpRequest} from '../../types/http/http-request.types';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    public app: NisApp;
    public appConfig: AppConfigTypes;

    public sessionTimedOut: Subject<NisHttpRequest> = new Subject<NisHttpRequest>();
    public redirectUrl: string;

    private _isLoggedIn: boolean = null;

    public get isNisApp(): boolean {
        return Object.values(NisApp).includes(this.app);
    }

    public get isNxApp(): boolean {
        return this.app === NisApp.nx;
    }

    public get isPaApp(): boolean {
        return this.app === NisApp.pa;
    }

    public get isConnectApp(): boolean {
        return this.app === NisApp.connect;
    }

    public get isAdminApp(): boolean {
        return this.app === NisApp.admin;
    }

    public get clientType(): string {
        if (this.isPaApp) {
            return 'pa';
        } else if (this.isConnectApp) {
            return 'connect';
        } else if (this.isAdminApp) {
            return 'admin';
        }
        return 'nx';
    }

    public set isLoggedIn(loggedIn: boolean) {
        this._isLoggedIn = loggedIn;
    }

    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }
}
