import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ToastService} from '../toast/toast.service';
import {IonicDefaultColor} from '../../types/color/color.types';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class NisHttpInterceptorService implements HttpInterceptor {
    constructor(private toastService: ToastService, private translateService: TranslateService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('/api/')) {
            return next.handle(req).pipe(tap({
                error: this.handleError.bind(this)
            }));
        }
        return next.handle(req);
    }

    private async handleError(response: HttpErrorResponse): Promise<void> {
        if (response.status !== 401) {
            let errorMessage: string = this.translateService.instant('nis.generic_error');
            let error: any = response.error;
            if (typeof error === 'string' && response.headers.get('content-type') === 'application/json') {
                try {
                    error = JSON.parse(error);
                } catch (e) {
                    console.error(e.message);
                }
            }

            if (error?.details) {
                errorMessage = error.details;
            } else if (error?.message) {
                errorMessage = error.message;
            } else if (response.message) {
                errorMessage = response.message;
            }
            await this.toastService.presentToastWithOptions(errorMessage, IonicDefaultColor.danger, 5000);
        }
    }
}
