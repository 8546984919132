import {Directive, HostBinding, NgZone} from '@angular/core';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {ViewOrientation} from '../../helpers/view-orientation.enum';
import {KeyboardService} from '../../services/keyboard/keyboard.service';
import {PlatformService} from '../../services/platform/platform.service';
import {ElectronService} from '../../services/electron/electron.service';

@Directive({
    selector: '[nisResponsive]'
})
export class ResponsiveDirective {
    @HostBinding('class.isMobile') mobile = false;
    @HostBinding('class.isDesktop') desktop = false;

    @HostBinding('class.isTablet') tablet = false;
    @HostBinding('class.isPhone') phone = false;

    @HostBinding('class.isPortrait') portrait = false;
    @HostBinding('class.isLandscape') landscape = false;

    private viewOrientation: ViewOrientation = null;

    constructor(private platformService: PlatformService,
                private screenOrientation: ScreenOrientation,
                private keyboardService: KeyboardService,
                private electronService: ElectronService,
                private ngZone: NgZone) {

        this.platformService.ready().then(() => {
            this.addResponsiveClasses();

            if (this.platformService.isApp) {
                this.platformService.resume.subscribe(() => {
                    this.onResize();
                });

                this.screenOrientation.onChange().subscribe(() => {
                    setTimeout(() => {
                        this.onResize();
                    }, 500);
                });

                if (this.platformService.isElectron) {
                    this.electronService.ipcRenderer.on('screen-display-metrics-changed', () => {
                        this.onResize();
                    })
                }

                if (this.platformService.isIOS) {
                    this.keyboardService.keyboardDidShow.subscribe(() => {
                        this.onResize();
                    });
                    this.keyboardService.keyboardDidHide.subscribe(() => {
                        this.onResize();
                    });
                }
            }

            this.platformService.resize.subscribe(() => {
                this.onResize();
            });
        });
    }

    private onResize() {
        this.ngZone.run(() => {
            this.addResponsiveClasses();
            this.platformService.updateIonSafeAreas();
            this.platformService.afterResize.next();
        });
    }

    private addResponsiveClasses() {
        this.desktop = this.platformService.isDesktop;
        this.mobile = this.platformService.isMobile;
        if (this.mobile) {
            this.tablet = this.platformService.isTablet;
            this.phone = this.platformService.isPhone;
        }

        this.portrait = this.platformService.isPortrait;
        this.landscape = this.platformService.isLandscape;
        const prevViewOrientation = this.viewOrientation;
        if (this.portrait) {
            this.viewOrientation = ViewOrientation.Portrait;
        } else {
            this.viewOrientation = ViewOrientation.Landscape;
        }

        if (prevViewOrientation !== this.viewOrientation) {
            if (this.phone) {
                this.viewOrientation = ViewOrientation.Portrait;
                this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT).catch(() => {
                        console.warn('NotSupportedError: screen.orientation.lock() on this device');
                    }
                );
            } else {
                this.screenOrientation.unlock();
            }
            this.platformService.viewOrientationChanged.next(this.viewOrientation);
        }
    }
}
