import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subscriber} from 'rxjs';
import {NisHttpRequest, NisHttpRequestType} from '../../types/http/http-request.types';
import {NisHttpAppService} from './nis-http-app.service';
import {AppService} from '../app/app.service';
import {PlatformService} from '../platform/platform.service';
import {Utils} from '../../helpers/utils';
import {ElectronService} from '../electron/electron.service';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NisHttpWrapperService {
    private _serverUrl: string = '';
    private readonly sessionSessionStatePath = 'session/sessionState';

    constructor(private platformService: PlatformService,
                private httpClient: HttpClient,
                private httpApp: NisHttpAppService,
                private appService: AppService,
                private electronService: ElectronService) {
    }

    public set serverUrl(value: string) {
        this._serverUrl = value;
        if (this.platformService.isElectron) {
            let referer;
            if (this.appService.isLoggedIn) {
                referer = this.determineUrl(this.appService.app);
            }
            this.electronService.ipcRenderer.invoke('session-webRequest', referer);
        }
    }

    public get serverUrl(): string {
        return this._serverUrl;
    }

    public get<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }, doHandleResponse: boolean = true): Observable<T> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<T>) => {
                this.httpApp.get(
                    {
                        requestData: {
                            type: NisHttpRequestType.get, url: this.determineUrl(url),
                            options: options
                        },
                        subscriber: subscriber
                    }, doHandleResponse);
            });
        } else {
            return this.httpClient.get<T>(this.determineUrl(url), options);
        }
    }

    patch<T>(url: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        if (this.platformService.isApp) {
            // not implemented yet
        } else {
            return this.httpClient.patch<T>(this.determineUrl(url), body, options);
        }
    }

    public determineUrl(url?: string) {
        if (Utils.isNullOrUndefined(url)) {
            return this._serverUrl + `/${environment.baseUrlSegment}/`;
        } else if (!url.includes('://')) {
            if (url.includes('api/') || url.includes('resource/')) {
                return this._serverUrl + '/' + url;
            } else {
                return this._serverUrl + `/${environment.baseUrlSegment}/` + url;
            }
        } else {
            return url;
        }
    }

    public getBlob(url: string, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType: 'blob';
        withCredentials?: boolean;
    } = { responseType: 'blob' }, doHandleResponse: boolean = true): Observable<Blob> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<Blob>) => {
                this.httpApp.get(
                    {
                        requestData: {
                            type: NisHttpRequestType.get, url: this.determineUrl(url),
                            options: options
                        },
                        subscriber: subscriber
                    }, doHandleResponse);
            });
        } else {
            return this.httpClient.get(this.determineUrl(url), options);
        }
    }

    public getText(url: string, options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType: 'text';
        withCredentials?: boolean;
    } = { responseType: 'text' }): Observable<string> {
        if (this.platformService.isIOS || this.platformService.isAndroid) {
            return new Observable((subscriber: Subscriber<string>) => {
                this.httpApp.getText(
                    {
                        requestData: {
                            type: NisHttpRequestType.getText, url: this.determineUrl(url),
                            options: options
                        },
                        subscriber: subscriber
                    });
            });
        } else {
            return this.httpClient.get(this.determineUrl(url), options);
        }
    }

    public performDownloadRequest(url: string, fileName: string) {
        return new Observable((subscriber: Subscriber<string>) => {
            this.get(this.sessionSessionStatePath).subscribe((loggedIn: any) => {
                if (loggedIn.loggedIn) {
                    this.httpApp.download(url, fileName, subscriber);
                } else {
                    this.appService.sessionTimedOut.next(
                        {
                            requestData: {
                                type: NisHttpRequestType.download,
                                url: url,
                                fileName: fileName
                            },
                            subscriber: subscriber
                        });
                }
            });
        });
    }

    public openFile(url: string) {
        const fileUrl = this.determineUrl(url);
        if (!(this.platformService.isIOS || this.platformService.isAndroid)) {
            this.openFileInWindow(fileUrl);
            // TODO: session timeout handling necessary? -> logout ?
        }
    }

    public uploadImage<T>(url: string, body: any | null, filePath: string, newName: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<T>) => {
                this.httpApp.uploadFile(
                    {
                        requestData: {
                            type: NisHttpRequestType.upload,
                            url: this.determineUrl(url),
                            body: body,
                            options: options,
                            fileName: filePath,
                            newName: newName
                        },
                        subscriber: subscriber
                    });
            });
        } else {
            // TODO currently we only use Android
        }
    }

    post<T>(url: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }, serializer?: 'json' | 'multipart' | 'urlencoded'): Observable<T> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<T>) => {
                this.httpApp.post(
                    {
                        requestData: {
                            type: NisHttpRequestType.post, url: this.determineUrl(url), body: body,
                            options: options, serializer: serializer
                        },
                        subscriber: subscriber
                    });
            });
        } else {
            return this.httpClient.post<T>(this.determineUrl(url), body, options);
        }
    }

    /**
     * Construct a PUT request which interprets the body as text and returns it.
     *
     * @return an `Observable` of the body as a `string`.
     */
    put<T>(url: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<T>) => {
                this.httpApp.put(
                    {
                        requestData: {
                            type: NisHttpRequestType.put, url: this.determineUrl(url),
                            body: body, options: options
                        },
                        subscriber: subscriber
                    });
            });
        } else {
            return this.httpClient.put<T>(this.determineUrl(url), body, options);
        }
    }

    delete<T>(url: string, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<T> {
        if (this.platformService.isApp) {
            return new Observable((subscriber: Subscriber<T>) => {
                this.httpApp.delete(
                    {
                        requestData: {
                            type: NisHttpRequestType.put, url: this.determineUrl(url),
                            options: options
                        },
                        subscriber: subscriber
                    });
            });
        } else {
            return this.httpClient.delete<T>(this.determineUrl(url), options);
        }
    }

    public clearCookies(): void {
        this.httpApp.clearCookies();
    }

    public async retryRequest(request: NisHttpRequest) {
        switch (request.requestData.type) {
            case NisHttpRequestType.get:
                this.httpApp.get(request);
                break;
            case NisHttpRequestType.getText:
                this.httpApp.getText(request);
                break;
            case NisHttpRequestType.post:
                this.httpApp.post(request);
                break;
            case NisHttpRequestType.put:
                this.httpApp.put(request);
                break;
            case NisHttpRequestType.download:
                if (this.platformService.isElectron) {
                    await this.openFileInWindow(request.requestData.url);
                } else {
                    this.httpApp.download(request.requestData.url, request.requestData.fileName, request.subscriber);
                }
                break;
            default:
                break;
        }
    }

    public downloadAuthenticatedFile(url: string, filename?: string) {
        this.handleBlobResponse(this.getBlob(url), filename);
    }

    private handleBlobResponse(responseObservable: Observable<Blob>, filename?: string) {
        responseObservable.subscribe(
            async (response: Blob) => {
                const url: string = URL.createObjectURL(response);
                await this.openAndDownloadFile(url, filename);
                URL.revokeObjectURL(url);
            });
    }

    private async openFileInWindow(url: string) {
        if (this.platformService.isElectron) {
            await this.openAndDownloadFile(url);
        } else {
            window.open(url, '_blank');
        }
    }

    private async openAndDownloadFile(urlPath: string, filename?: string) {
        const a = document.createElement('a') as HTMLAnchorElement;
        a.href = urlPath;
        a.style.display = 'none';
        a.target = '_blank';
        if (filename) {
            a.download = filename;
        }
        document.body.appendChild(a);
        a.click();

        // Chrome requires the timeout
        await setTimeout(null, 100);
        a.remove();
    }
}
