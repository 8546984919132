import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Subject} from 'rxjs';
import {ViewOrientation} from '../../helpers/view-orientation.enum';
import {ElectronService} from '../electron/electron.service';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {
    public afterResize: Subject<void> = new Subject<void>();
    public resume: Subject<void> = new Subject<void>();
    public resize: Subject<void> = new Subject<void>();

    viewOrientationChanged: Subject<ViewOrientation> = new Subject<ViewOrientation>();
    viewOrientation: ViewOrientation;

    private breakpointPortrait: number = 768;
    private ionSafeAreaTop: number;
    private ionSafeAreaBottom: number;

    constructor(private platform: Platform, private electronService: ElectronService) {
        this.platform.resize.subscribe(() => {
            this.resize.next();
        });
        this.platform.resume.subscribe(() => {
            this.resume.next();
        });
        this.viewOrientationChanged.subscribe((panelView: ViewOrientation) => {
            this.viewOrientation = panelView;
        });
    }

    public get isApp() {
        return this.isIOS || this.isAndroid || this.isElectron;
    }

    public get isElectron() {
        return this.electronService.isElectron;
    }

    public get isAndroid() {
        return this.platform.is('android');
    }

    public get isIOS() {
        return this.platform.is('ios');
    }

    public get isTablet() {
        return this.platform.is('tablet') || this.platform.is('ipad');
    }

    public get isPhone() {
        return !this.isTablet || this.platform.is('iphone');
    }

    public get isDesktop() {
        return this.platform.is('desktop') || this.platform.is('mobileweb');
    }

    public get isMobile() {
        return !this.isDesktop;
    }

    public get isPortrait() {
        const orientation: any = screen.orientation;
        let orientationType: string;
        // Fix for edge because screen.orientation is not defined
        if (orientation) {
            orientationType = orientation.type;
        } else if (screen['msOrientation']) {
            orientationType = screen['msOrientation'];
        }
        if (orientationType) {
            return orientationType.includes('portrait') || (this.platform.width() < this.breakpointPortrait);
        } else {
            return false;
        }
    }

    public get isLandscape() {
        return !this.isPortrait;
    }

    public ready(): Promise<string> {
        return this.platform.ready();
    }

    updateIonSafeAreas() {
        setTimeout(() => {
            const top: number = parseInt(getComputedStyle(document.body).getPropertyValue('--ion-safe-area-top'), 10);
            this.ionSafeAreaTop = top ? top : 0;

            const bottom = parseInt(getComputedStyle(document.body).getPropertyValue('--ion-safe-area-bottom'), 10);
            this.ionSafeAreaBottom = bottom ? bottom : 0;
        });
    }

    getIonSafeAreaTop(): number {
        if (this.ionSafeAreaTop === undefined) {
            this.updateIonSafeAreas();
            return 0;
        }
        return this.ionSafeAreaTop;
    }

    getIonSafeAreaBottom(): number {
        if (this.ionSafeAreaBottom === undefined) {
            this.updateIonSafeAreas();
            return 0;
        }
        return this.ionSafeAreaBottom;
    }
}
