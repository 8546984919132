import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Subscriber} from 'rxjs';
import {HTTP, HTTPResponse} from '@awesome-cordova-plugins/http/ngx';
import {NisHttpRequest, NisHttpRequestType} from '../../types/http/http-request.types';
import {AppService} from '../app/app.service';
import {PlatformService} from '../platform/platform.service';
import {MessageService} from '../message/message.service';
import {ProjectContainerService} from '../design/project-container.service';
import {Directory, DownloadFileResult, Filesystem} from '@capacitor/filesystem';

@Injectable({
    providedIn: 'root'
})
export class NisHttpAppService {
    constructor(private httpClient: HttpClient,
                private httpCordova: HTTP,
                private appService: AppService,
                private platformService: PlatformService,
                private messageService: MessageService,
                private projectContainerService: ProjectContainerService) {
    }

    get<T>(request: NisHttpRequest, doHandleResponse: boolean = true) {
        if (this.platformService.isElectron) {
            this.httpClient.get<T>(request.requestData.url, request.requestData.options).toPromise().then(
                (response) => {
                    if (doHandleResponse) {
                        this.handleElectronResponse<T>(response, request.subscriber, request);
                    } else {
                        request.subscriber.next(response);
                        request.subscriber.complete();
                    }
                }, (response: HttpErrorResponse) => {
                    this.handleElectronErrorResponse<T>(response, request.subscriber, request);
                });
        } else {
            const cordovaOptions = this.getCordovaParameters(request.requestData.options);
            this.addAdditionalParams(request, cordovaOptions.params);
            this.httpCordova.get(request.requestData.url, cordovaOptions.params, cordovaOptions.headers).then(
                (response: HTTPResponse) => {
                    if (doHandleResponse) {
                        this.handleCordovaResponse<T>(response, request.subscriber, request);
                    } else {
                        request.subscriber.next(response.data);
                        request.subscriber.complete();
                    }
                },
                (response: HTTPResponse) => {
                    this.handleCordovaErrorResponse<T>(response, request.subscriber);
                });
        }
    }

    getText<T>(request: NisHttpRequest) {
        if (this.platformService.isElectron) {
            this.httpClient.get<T>(request.requestData.url, request.requestData.options).toPromise().then(
                (response) => {
                    this.handleElectronResponse<T>(response, request.subscriber, request);
                }, (response: HttpErrorResponse) => {
                    this.handleElectronErrorResponse<T>(response, request.subscriber, request);
                });
        } else {
            const cordovaOptions = this.getCordovaParameters(request.requestData.options);
            this.addAdditionalParams(request, cordovaOptions.params);
            this.httpCordova.get(request.requestData.url, cordovaOptions.params, cordovaOptions.headers).then(
                (response: HTTPResponse) => {
                    if (response.data.indexOf('__this__is__the__nisXplorer__login__page__') > -1) {
                        this.relogin(request);
                    } else {
                        request.subscriber.next(response.data);
                        request.subscriber.complete();
                    }
                },
                (response: HTTPResponse) => {
                    this.handleCordovaErrorResponse<T>(response, request.subscriber);
                });
        }
    }

    post<T>(request: NisHttpRequest) {
        if (this.platformService.isElectron) {
            this.httpClient.post<T>(request.requestData.url, request.requestData.body,
                request.requestData.options).toPromise().then(
                (response) => {
                    this.handleElectronResponse<T>(response, request.subscriber, request);
                }, (response: HttpErrorResponse) => {
                    this.handleElectronErrorResponse<T>(response, request.subscriber, request);
                });
        } else {
            let additionalHeaders: any = {};
            if (request.requestData.options && request.requestData.options.headers) {
                additionalHeaders = request.requestData.options.headers;
            }
            let body: any = request.requestData.body;
            if (request.requestData.body && request.requestData.body instanceof HttpParams) {
                body = {};
                for (const key of request.requestData.body.keys()) {
                    body[key] = request.requestData.body.get(key);
                }
            }
            this.httpCordova.setDataSerializer(request.requestData.serializer || 'json');
            this.addAdditionalParams(request, body);
            this.httpCordova.post(request.requestData.url, body, additionalHeaders).then(
                (response: HTTPResponse) => {
                    this.handleCordovaResponse<T>(response, request.subscriber, request);
                }, (response: HTTPResponse) => {
                    this.handleCordovaErrorResponse<T>(response, request.subscriber);
                });
        }
    }

    uploadFile<T>(request: NisHttpRequest) {
        let additionalHeaders: any = {};
        if (request.requestData.options && request.requestData.options.headers) {
            additionalHeaders = request.requestData.options.headers;
        }
        this.httpCordova.setDataSerializer('json');
        this.httpCordova.uploadFile(request.requestData.url, request.requestData.body, additionalHeaders,
            request.requestData.fileName,
            request.requestData.newName).then(
            (response: HTTPResponse) => {
                this.handleCordovaResponse<T>(response, request.subscriber, request);
            }, (response: HTTPResponse) => {
                this.handleCordovaErrorResponse<T>(response, request.subscriber);
            });
    }

    /**
     * Construct a PUT request which interprets the body as text and returns it.
     *
     * @return an `Observable` of the body as a `string`.
     */
    put<T>(request: NisHttpRequest) {
        if (this.platformService.isElectron) {
            this.httpClient.put<T>(request.requestData.url, request.requestData.body,
                request.requestData.options).toPromise().then(
                (response) => {
                    this.handleElectronResponse<T>(response, request.subscriber, request);
                }, (response: HttpErrorResponse) => {
                    this.handleElectronErrorResponse<T>(response, request.subscriber, request);
                });
        } else {
            let additionalHeaders: any = {};
            if (request.requestData.options && request.requestData.options.headers) {
                additionalHeaders = request.requestData.options.headers;
            }
            this.httpCordova.setDataSerializer('json');
            this.addAdditionalParams(request, request.requestData.body);
            this.httpCordova.put(request.requestData.url, request.requestData.body, additionalHeaders).then(
                (response: HTTPResponse) => {
                    this.handleCordovaResponse<T>(response, request.subscriber, request);
                }, (response: HTTPResponse) => {
                    this.handleCordovaErrorResponse<T>(response, request.subscriber);
                });
        }
    }

    delete<T>(request: NisHttpRequest) {
        if (this.platformService.isElectron) {
            this.httpClient.delete(request.requestData.url, request.requestData.options).toPromise().then(
                (response) => {
                    this.handleElectronResponse<T>(response, request.subscriber, request);
                }, (response: HttpErrorResponse) => {
                    this.handleElectronErrorResponse<T>(response, request.subscriber, request);
                });
        } else {
            let additionalHeaders: any = {};
            if (request.requestData.options && request.requestData.options.headers) {
                additionalHeaders = request.requestData.options.headers;
            }
            this.httpCordova.setDataSerializer('json');
            this.addAdditionalParams(request, request.requestData.body);
            this.httpCordova.delete(request.requestData.url, request.requestData.body, additionalHeaders).then(
                (response: HTTPResponse) => {
                    this.handleCordovaResponse<T>(response, request.subscriber, request);
                }, (response: HTTPResponse) => {
                    this.handleCordovaErrorResponse<T>(response, request.subscriber);
                });
        }
    }

    public download(url: string, fileName: string, subscriber: Subscriber<string>) {
        if (this.platformService.isAndroid) {
            fileName = encodeURI(fileName);
        }
        url = url.replace(/:\d{1,5}\//g, '/');
        Filesystem.downloadFile({
            url: this.addAdditionalParamsToUrl(url),
            path: fileName,
            directory: Directory.Data
        }).then((response: DownloadFileResult) => {
                subscriber.next(response.path);
                subscriber.complete();
            }
        ).catch((reason) => {
            console.log(reason);
            subscriber.error(reason);
        })
    }

    public clearCookies(): void {
        if (this.platformService.isElectron) {
            document.cookie = 'cookiename= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        } else {
            this.httpCordova.clearCookies();
        }
    }

    private relogin(request: NisHttpRequest) {
        this.clearCookies();
        this.appService.sessionTimedOut.next(request);
    }

    private handleCordovaResponse<T>(response: HTTPResponse, subscriber: Subscriber<T>,
                                     request: NisHttpRequest) {
        if (response.data.indexOf('__this__is__the__nisXplorer__login__page__') > -1
            && request.requestData.url.indexOf('session/logout') < 1) {
            this.relogin(request);
        } else {
            if (response.headers['content-type'].indexOf('application/json') > -1) {
                subscriber.next(JSON.parse(response.data) as T);
            } else if (response.headers['content-type'].indexOf('text/html') > -1) {
                if (response.data.indexOf('__this__is__the__nisXplorer__fail_login__page__') > -1) {
                    this.clearCookies();
                    subscriber.error('nis.login_page.failed_login_part');
                } else {
                    subscriber.error('nis.login_page.server_not_reachable');
                }
            } else {
                subscriber.next(response.data);
            }
            subscriber.complete();
        }
    }

    private handleCordovaErrorResponse<T>(response: any, subscriber: Subscriber<T>) {
        const httpErrorResponse: HttpErrorResponse = this.messageService.throwErrorMessage(response);
        if (httpErrorResponse) {
            subscriber.error(httpErrorResponse.error);
        }
    }

    private handleElectronResponse<T>(response: any, subscriber: Subscriber<T>, request: NisHttpRequest) {
        if (typeof response === 'string' && request.requestData.url.indexOf('session/logout') < 1
            && response.indexOf('__this__is__the__nisXplorer__login__page__') > -1) {
            this.relogin(request);
        } else {
            subscriber.next(response);
            subscriber.complete();
        }
    }

    private handleElectronErrorResponse<T>(response: HttpErrorResponse, subscriber: Subscriber<T>,
                                           request: NisHttpRequest) {
        let errorMessage: string;
        if (response.status <= 0 || response.status === 503) {
            errorMessage = 'nis.login_page.server_not_reachable';
        } else if (response.status === 408) {
            // TODO: Handle missing cookie before login
        } else if (response.status === 200) {
            if (response.error && response.error.text) {
                if (response.error.text.indexOf('__this__is__the__nisXplorer__login__page__') !== -1
                    && request.requestData.url.indexOf('session/logout') < 1) {
                    this.relogin(request);
                } else if (response.error.text.indexOf(
                    '__this__is__the__nisXplorer__fail_login__page__') !== -1) {
                    errorMessage = 'nis.login_page.failed_login_part';
                } else {
                    errorMessage = 'nis.login_page.server_not_reachable';
                }
            }
        } else if (response.status === 403) {
            errorMessage = 'nis.no_permission_general';
        } else {
            errorMessage = 'nis.error_message';
        }

        if (!MessageService.suppressError && errorMessage) {
            const error = new HttpErrorResponse(
                {
                    status: response.status,
                    error: errorMessage,
                    url: response.url,
                    headers: response.headers,
                    statusText: response.statusText
                }
            );
            MessageService.onError.next(error);
        }
        if (errorMessage) {
            subscriber.error(errorMessage);
        }
    }

    private getCordovaParameters(options: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        params?: HttpParams | {
            [param: string]: string | string[];
        };
    }): {
        params: {
            [param: string]: string | string[];
        };
        headers: {
            [header: string]: string | string[];
        };
    } {
        const params: {
            [param: string]: string | string[];
        } = {};
        let headers: {
            [header: string]: string | string[];
        } = {};

        let paramsObj: any = {};

        if (options) {
            const optionsParams: HttpParams | {
                [param: string]: string | string[];
            } = options.params;
            const optionsHeaders: HttpHeaders | {
                [header: string]: string | string[];
            } = options.headers;

            if (optionsParams instanceof HttpParams) {
                optionsParams.keys().forEach((key: string) => {
                    paramsObj[key] = optionsParams.get(key);
                });
            } else {
                paramsObj = optionsParams;
            }
            if (optionsHeaders instanceof HttpHeaders) {
                const headersArray = optionsHeaders.keys().map(x => ({ [x]: optionsHeaders.get(x) }));
                headers = JSON.parse(JSON.stringify(headersArray));
            } else {
                headers = optionsHeaders;
            }
        }
        return {
            params: paramsObj,
            headers: headers
        };
    }

    private addAdditionalParams(request: NisHttpRequest, params: any) {
        if (request.requestData.type === NisHttpRequestType.get || request.requestData.type === NisHttpRequestType.delete) {
            const projectContainerInfo = this.projectContainerService.getAdditionalParamsArray();
            if (projectContainerInfo) {
                for (const key of Object.getOwnPropertyNames(projectContainerInfo)) {
                    params[key] = projectContainerInfo[key];
                }
            }
        } else {
            request.requestData.url = this.addAdditionalParamsToUrl(request.requestData.url);
        }
    }

    private addAdditionalParamsToUrl(url: string) {
        const projectContainerInfo = this.projectContainerService.getAdditionalParamsString(url);
        if (projectContainerInfo) {
            return  url + projectContainerInfo;
        }
        return url;
    }
}
