import {Injectable} from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import {ipcRenderer, webFrame} from 'electron';
import * as fs from 'fs';

@Injectable({
    providedIn: 'root'
})
export class ElectronService {
    ipcRenderer: typeof ipcRenderer;
    webFrame: typeof webFrame;
    fs: typeof fs;

    constructor() {
        if (this.isElectron) {
            this.ipcRenderer = window.require('electron').ipcRenderer;
            this.webFrame = window.require('electron').webFrame;
            this.fs = window.require('fs');
        }
    }

    get isElectron(): boolean {
        return !!(window && window.process && window.process.type);
    }

    public get isMacOS(): boolean {
        return this.isElectron && process.platform === 'darwin';
    }

    public get isWindows(): boolean {
        return this.isElectron && process.platform === 'win32';
    }

    public get isLinux(): boolean {
        return this.isElectron && process.platform === 'linux';
    }

    public get isX86(): boolean {
        return this.isElectron && process.arch === 'ia32';
    }

    public get isX64(): boolean {
        return this.isElectron && process.arch === 'x64';
    }

    public get isArm(): boolean {
        return this.isElectron && process.arch === 'arm';
    }
}
