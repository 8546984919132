import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {APP_BASE_HREF, LOCATION_INITIALIZED, PlatformLocation, registerLocaleData} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {IonicModule} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {NisHttpInterceptorService} from './nis-lib/services/http/nis-http-interceptor.service';
import {DirectivesModule} from './nis-lib/directives/directives.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {ServiceLocator} from './nis-lib/helpers/service-locator';
import {LoadingModule} from './nis-lib/components/loading/loading.module';
import {RouteReuseStrategy, UrlSerializer} from '@angular/router';
import {NisRouteReuseStrategy} from './route-reuse-strategy';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import {CookieService} from 'ngx-cookie-service';
import {NisUrlSerializer} from './url-serializer';
import {Diagnostic} from '@awesome-cordova-plugins/diagnostic/ngx';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NisTranslateService} from './nis-lib/services/i18n/nis-translate.service';
import {PlatformService} from './nis-lib/services/platform/platform.service';
import {IonicStorageModule} from '@ionic/storage-angular';
import {Drivers} from '@ionic/storage';
import {NisDesignHttpInterceptorService} from './nis-lib/services/design/nis-design-http-interceptor.service';
import {AuthInitConfigService} from './nis-lib/services/auth/auth-init-config.service';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {switchMap} from 'rxjs/operators';
import {fromPromise} from 'rxjs-compat/observable/fromPromise';
import {NisAuthGuardService} from './nis-lib/services/auth/nis-auth-guard.service';
import {NisKeycloakAuthGuard} from './nis-lib/services/auth/keycloak-auth-guard.service';
import {of} from 'rxjs';
import {NisKeycloakConfig} from './nis-lib/types/auth/auth.types';
import {Utils} from './nis-lib/helpers/utils';
import {NisApp} from './nis-lib/types/nis/nis.types';
import {environment} from '../environments/environment';
// eslint-disable-next-line boundaries/no-ignored

registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeIt);

const appInitializerFactory = (nisTranslateService: NisTranslateService,
                               platformService: PlatformService,
                               injector: Injector,
                               keycloak: KeycloakService,
                               authInitConfig: AuthInitConfigService) => async (): Promise<void> => {
    const app: NisApp = Utils.getNisAppFromUrl(window.location.href);

    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    await authInitConfig.getConfig()
        .pipe(
            switchMap((config: NisKeycloakConfig) => {
                if (config) {
                    return fromPromise(keycloak.init({
                        config: {
                            url: config.keycloakUrl,
                            realm: config.keycloakRealm,
                            clientId: JSON.parse(config.keycloakClientIds)[app]
                        },
                        initOptions: {
                            onLoad: 'check-sso',
                            silentCheckSsoRedirectUri: window.location.origin
                                + (environment.production ? `/${environment.baseUrlSegment}` : '')
                                + '/assets/config/auth/silent-check-sso.html'
                        },
                        shouldAddToken: (request: { method: string; url: string; }) => {
                            const { method, url } = request;
                            const isGetRequest = 'GET' === method.toUpperCase();
                            const acceptablePaths = ['assets/'];
                            const isAcceptablePathMatch = acceptablePaths.some((path) =>
                                url.includes(path)
                            );

                            return !(isGetRequest && isAcceptablePathMatch);
                        }
                    }));
                } else {
                    return of(null);
                }
            })
        ).toPromise();

    await nisTranslateService.init();
    await platformService.ready();
};

const nisTranslateHttpLoaderFactory = (http: HttpClient) =>
    new TranslateHttpLoader(http, 'assets/i18n/nis/', '.json');

const getBaseHref = (platformLocation: PlatformLocation): string => {
    return platformLocation.getBaseHrefFromDOM();
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        IonicStorageModule.forRoot({
            name: '__mob',
            driverOrder: [Drivers.LocalStorage]
        }),
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useFactory: nisTranslateHttpLoaderFactory, deps: [HttpClient] },
            isolate: false
        }),
        LoadingModule,
        AppRoutingModule,
        KeycloakAngularModule,
        DirectivesModule,
        BrowserAnimationsModule
    ],
    providers: [
        HTTP,
        ScreenOrientation,
        Diagnostic,
        { provide: APP_BASE_HREF, useValue: '/GSS-Web-NIS' },
        { provide: HTTP_INTERCEPTORS, useClass: NisHttpInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NisDesignHttpInterceptorService, multi: true },
        { provide: RouteReuseStrategy, useClass: NisRouteReuseStrategy },
        { provide: UrlSerializer, useClass: NisUrlSerializer },
        { provide: NisAuthGuardService, useClass: NisKeycloakAuthGuard },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [NisTranslateService, PlatformService, Injector, KeycloakService, AuthInitConfigService],
            multi: true
        },
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
        // Create global Service Injector
        ServiceLocator.rootInjector = this.injector;
    }
}
