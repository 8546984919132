import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {NisAuthGuardService} from './nis-auth-guard.service';
import {NisApp, NisAppAuthorization} from '../../types/nis/nis.types';
// eslint-disable-next-line boundaries/no-ignored
import {environment} from '../../../../environments/environment';

@Injectable()
export class NisKeycloakAuthGuard extends KeycloakAuthGuard implements NisAuthGuardService {
    constructor(
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService
    ) {
        super(router, keycloak);
    }

    public async isAccessAllowed(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        const app: NisApp = route.params['app'] as NisApp;
        const baseUrl: string = `${window.location.origin}/${environment.baseUrlSegment}`;

        if (!this.authenticated) {
            await this.keycloak.login({
                redirectUri: `${baseUrl}/${state.url}`
            });
        }

        const authorizations: NisAppAuthorization[] = route.data.authorizations;
        const authorization: NisAppAuthorization = authorizations.find((auth: NisAppAuthorization) => auth.app === app);
        const accessAllowed: boolean = this.roles.includes(authorization.role);

        if (!accessAllowed && this.authenticated) {
            await this.keycloak.logout(`${baseUrl}/${app}?authorization_failure`);
        }

        return accessAllowed;
    }
}
