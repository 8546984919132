import {Component} from '@angular/core';

import {Event, ResolveEnd, Router} from '@angular/router';
import {PlatformService} from './nis-lib/services/platform/platform.service';
import {SplashScreen} from '@capacitor/splash-screen';

@Component({
    selector: 'nis-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    showApp: boolean = false;

    constructor(private platformService: PlatformService,
                private router: Router) {
        this.initializeApp();

        const routerSubscription = router.events.subscribe((event: Event) => {
            if (event instanceof ResolveEnd) {
                this.showApp = true;
                routerSubscription.unsubscribe();
            }
        });
    }

    initializeApp() {
        this.platformService.ready().then(() => {
            if (this.platformService.isApp) {
                SplashScreen.hide();
            }
        });
    }
}
