import {Injectable} from '@angular/core';
import {AlertButton, AlertInput} from '@ionic/core';
import {AlertMessageButton} from '../../types/general/popup.types';
import {TranslateService} from '@ngx-translate/core';
import {AlertController} from '@ionic/angular';
import {Subject} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    public static onError: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();
    public static suppressError: boolean = false;

    constructor(private translateService: TranslateService,
                private alertController: AlertController) {
    }

    async displayMessage(message: string, title?: string,
                         buttons: AlertButton[] = [new AlertMessageButton('Ok')],
                         inputs: AlertInput[] = [],
                         allowDismiss: boolean = true): Promise<void> {
        const alert = await this.alertController.create({
            mode: 'md',
            header: title ? this.translateService.instant(title) : '',
            message: this.translateService.instant(message),
            inputs: inputs,
            buttons: buttons,
            backdropDismiss: allowDismiss
        });
        await alert.present();
    }

    public throwErrorMessage(httpError: HttpErrorResponse) {
        let errorMessage: string;

        if (httpError.status <= 0 || httpError.status === 503) {
            errorMessage = 'nis.login_page.server_not_reachable';
        } else if (httpError.status === 500) {
            errorMessage = 'nis.error_message';
        } else if (httpError.status === 403) {
            errorMessage = 'nis.no_permission_general';
        } else if (httpError.status === 408 || httpError.status === 404 && httpError.url.includes('j_security')) {
            return;
        }
        const error = new HttpErrorResponse(
            {
                status: httpError.status,
                error: errorMessage ? errorMessage : httpError.error,
                url: httpError.url,
                headers: httpError.headers,
                statusText: httpError.statusText
            }
        );
        if (!MessageService.suppressError) {
            MessageService.onError.next(error);
        }
        return error;
    }
}
