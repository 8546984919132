import {Subscriber} from 'rxjs';

export enum NisHttpRequestType {
    get = 'get',
    delete = 'delete',
    getText = 'getText',
    download = 'download',
    post = 'post',
    put = 'put',
    upload = 'upload'
}

export class NisHttpRequest {
    requestData: NisHttpRequestDataType;
    subscriber?: Subscriber<any>;
}

export class NisHttpRequestDataType {
    type: NisHttpRequestType;
    url: string;
    body?: any | null;
    options?: any;
    fileName?: string;
    newName?: string;
    serializer?: 'raw' | 'json' | 'utf8' | 'urlencoded' | 'multipart';
}
